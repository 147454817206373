const adminRouter = {
  route: null,
  name: null,
  title: '用户管理',
  type: 'folder',
  icon: 'iconfont icon-huiyuanguanli',
  isElementIcon: false,
  filePath: 'view/admin/',
  order: null,
  inNav: true,
  permission: ['超级管理员独有权限'],
  children: [
    {
      title: '用户列表',
      type: 'view',
      name: 'userList',
      route: '/admin/user/list',
      filePath: 'view/admin/user/user-list.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
      isElementIcon: false,
      permission: ['超级管理员独有权限'],
    },
    {
      title: '添加用户',
      type: 'view',
      inNav: true,
      route: '/admin/user/add',
      icon: 'iconfont icon-add',
      isElementIcon: false,
      name: 'UserCreate',
      filePath: 'view/admin/user/user-create.vue',
      permission: ['超级管理员独有权限'],
    },
  ],
}

export default adminRouter
